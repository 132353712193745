import './App.css';

const imgFolder = process.env.PUBLIC_URL

const telephone = "347.449.1474"
const email     = "nycleeches@gmail.com"

const xrange = (n) => ([ ...new Array(n) ].map((e,i) => (i)))

// let font = "noto"
let font = "times"

const fancySvg5 = (start, low, med, high, unit) => {
  let longtail = xrange(10).map(e => `
    C 
      ${start+(unit*e)} ${med},
      ${start+(unit*(e+1))} ${med},
      ${start+(unit*(e+1))} ${e !== 9 ? high-10 : high}
    `).join(" ")
  return (
    <path
      d={`
        M 0 ${high}
        ${longtail}
      Z`}
    stroke="red" fill="transparent" />
  )
}

const fancySvg6 = (start, low, med, high, unit) => {
  let longtail = xrange(10).map(e => `
    C 
      ${start+(unit*e)} ${med},
      ${start+(unit*(e+1))} ${med},
      ${start+(unit*(e+1))} ${e !== 9 ? low+10 : low}
    `).join(" ")
  return (
    <path
      d={`
        M 0 ${low}
        ${longtail}
      Z`}
    stroke="red" fill="transparent" />
  )
}

// col-md >= 720px 
const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

const iridology = (
  <div className="mb-2 iridology-text ml-4 mr-4">
    Iridology is a painless and holistic analysis of the colored part of your eye, 
    to determine the condition of any area of your body. 
    It is a science and practice that helps to reveal the location of inflammation, 
    weaknesses and strengths, and in what stage they are manifesting. 
    This information is used to enable targeted detoxification/cleansing of the body with 
    organic herbs, nutrition and homeopathic programs. 
    Iridology is a valuable tool for anyone wanting to use a holistic approach to wellness.
</div>
)

const herbal = (
  <div className="mb-2 pl-4">
    <div className={`${"inline-caption"}`}>
      <img className={`mr-3 herbal ${vw >= 720 ? "mt-4" : ""}`} src={`${imgFolder}/img/logo-herbal.png`} />
      <span className="red">HERBAL DETOXIFICATION</span>
    </div>
    <div>
    The Herbal Detoxification is a holistic approach to faciliate cleansing the body of metabolic waste, 
    get rid of excess body fat, bring balance to the body, and thus to allow the organs to undertake proper functioning.
    </div>
  </div>
)

const leeches = (
  <div className={`mb-2 ${vw >= 720 ? "mt-5" : ""} pl-4`}>
    <div className="inline-caption">
      <img className="mr-3 leech" src={`${imgFolder}/img/logo-leech.png`} />
      <span className="red">LEECH THERAPY SPECIALIST</span>
    </div>
    <div>
    Medical leeches have a beneficial effect on the whole body. 
    Leech therapy normalizes energy exchange and thus strengthens the lymph flow, 
    activates the cleansing processes of the body and excretes toxins.
    </div>

    <div>
    Leeches have a positive effect on joint pain, sinus infections, swellings, 
    improve the supply of tissues and organs with oxygen and nutrients, restore energy and others.
    </div>

  </div>
)

const bemer = (
  <div className="mb-2 pl-4">
    <div className={`${vw >= 720 ? "" : ""}`}>
      <img className="mt-4 bemer" src={`${imgFolder}/img/logo-bemer.png`} />
      <div className={`red ${vw >= 1000 ? "mt-5" : ""}`}>BEMER-MICRO BLOOD CIRCULATION MAT</div>
    </div>
    <div>
    Too much stress, too little sleep, poor diet, unhealthy lifestyle and disease 
    can slow the movement of blood vessels that supply oxygen and nutrients to the cells.
    BEMER-Micro Blood Circulation Mat can improve blood circulation in the smallest blood vessels 
    and thus support the regeneration efforts of the body.
    </div>

    <div>
    * Individuals sessions using BEMER in-office located in Brooklyn, Williamsburg 
    (for an appointment call the phone number provided below)
    </div>
    <div>
    * To rent or purchase the mat, go to a web page: 
    <div><a href="http://www.bemeramerica.com/body" className="red">www.bemeramerica.com/body</a></div>
    </div>
    <div>
    * I assist with manual instructions
    </div>
  </div>
)

function App() {
  return (
    <div className={`App pt-3 ${font}`}>
        <h1 className="title" style={{textAlign: "center"}}>BK Holistics</h1>
      <div className="row">
      <div className="offset-1 col-10">
        <div style={{display: "flex", "justifyContent": "center"}}>
          <img className="banner" src={`${imgFolder}/img/icon-diagram.png`} />
        </div>
        <hr />
        {/* <div style={{display: "flex", justifyContent: "center"}}>
          <svg width="600px" height="40px" version="1.1" xmlns="http://www.w3.org/2000/svg">
            {fancySvg5(0,0,20,40,60)}
          </svg>
        </div> */}
        <h1 className="red iridology-h1" style={{textAlign: "center"}}>IRIDOLOGY</h1>
        {/* <div style={{display: "flex", justifyContent: "center"}}>
          <svg width="600px" height="40px" version="1.1" xmlns="http://www.w3.org/2000/svg">
            {fancySvg6(0,0,20,40,60)}
          </svg>
        </div> */}
        <hr />
        <h4 className="red iridology-caption" style={{textAlign: "center"}}>- YOUR EYE IS THE MAP OF YOUR BODY -</h4>
        <div className="bottomborder mb-3">
          {iridology}
        </div>
        <div className="row">
          <div className="col-md-6 pr-5">
            {herbal}
            {leeches}
          </div>
          <div className={`col-md-6 ${vw >= 761 ? "rightCol pl-5" : ""}`}>
            {bemer}
          </div>
        </div>

        <div className="footer mt-3 mb-5" style={{display: "flex", justifyContent: "center"}}>
          <div>
          For more information, please call:&nbsp;
          <span className="red">
            {telephone} (ask for Kazimierz/Casey)
          </span>
          
          &nbsp; or email <span className="red">{email}</span>
          </div> 

        </div>

      </div>
      <div className="col-1 placeholder" />
      </div>
    </div>
  );
}

export default App;
